.imageview-host {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, .1);

  .imageview-holder {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .imageview-holder,
  .imageview-image {
    animation: imageview-show 320ms;
  }
}

@keyframes imageview-show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  // bottom: 24px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  margin: 0;

  a {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    line-height: 1.5;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin: 0 20px;

    &:hover {
      opacity: .6;
    }
  }

}


// ---------------------------------------------------------

$control-color: #30caff;

.mask_editor_screen * {
  box-sizing: border-box;
}

.mask_editor_screen {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;

  .controlbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 52px;

    .control_name,
    .control_value {
      font-weight: 700;
      font-size: 24px;
      position: absolute;
      top: 16px;
      padding: 0;
      color: rgba(255, 255, 255, 0.6);
    }

    .control_name {
      left: 0;
    }

    .control_value {
      right: 0;
    }

    .control_holder {
      flex-grow: 1;
      width: 100%;

      input[type=range] {
        display: block;
        width: 100%;
        height: 8px;
        border-radius: 8px;
        background-color: #fff;
        -webkit-appearance: none;
        padding: 0;

        &::-webkit-slider-runnable-track {
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          background-color: #fff;
          height: 8px;
          padding: 0;
        }
        
        &::-webkit-slider-thumb {
          width: 24px;
          height: 24px;
          border: solid 4px #000;
          background-color: #01e777;
          cursor: pointer;
          -webkit-appearance: none;
          position: relative;
          z-index: 10;
          border-radius: 50%;
          margin-top: -8px;
        }

        &::-moz-range-track {
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          background-color: #fff;
          height: 8px;
          padding: 0;
        }
        
        &::-moz-range-thumb {
          width: 24px;
          height: 24px;
          border: solid 4px #000;
          background-color: #01e777;
          cursor: pointer;
          border-radius: 50%;
          position: relative;
          z-index: 10;
          margin-top: -12px;
        }

        &::-moz-range-progress {
          background-color: #43e5f7; 
        }
      }
    }
  }

  .toolbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 56px;

    .buttons-container {
      display: flex;
      align-items: center;

    }

    button {
      background: none;
      margin-right: 48px;

      &:last-child {
        margin: 0;
      }

      img {
        height: 40px;
      }
      &:disabled {
        opacity: .6;
      }
    }
  }

  .tool-save {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 2px;
    color: #000;
    width: 160px;
    height: 64px;
    border-radius: 40px;
    background-color: #01e777;
    text-transform: uppercase;
    margin: 0 auto;
  }

  .canvas_holder {
    flex-grow: 1;
    height: 100%;
    position: relative;
    margin-bottom: 32px;

    canvas {
      display: block;
    }

    .lower {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .upper {
      position: relative;
      z-index: 2;
      opacity: 0;
    }
  }

  .active-range {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 8px;
    background-color: #01e777;
    z-index: 0;
  }
}

@media all and (max-width: 560px) {
  input[type=range] {
    height: 4px;

    &::-webkit-slider-thumb {
      width: 16px;
      height: 16px;
      border: solid 2px #000;
      margin-top: -6px;
    }

    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border: solid 4px #000;
      margin-top: -6px;
    }
  }

  .mask_editor_screen {
    position: relative;
    padding-top: 48px;

    .controlbox {
      width: 100%;
      padding: 0 16px;
      margin: 0 auto 32px;

      .control_name,
      .control_value {
        font-size: 16px;
      }

      .control_name {
        left: 16px;
      }

      .control_value {
        right: 16px;
      }
    }

    .toolbox {
      padding: 0 16px;
      margin-bottom: 22px;

      button {
        margin-right: 32px;
        &:last-child {
          margin: 0;
        }
        img {
          height: 32px;
        }
      }

      .buttons-container-arrow {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .buttons-container-tools {
        width: 100%;
        justify-content: center;
        position: relative;
      }

      .tool-viewmode {
        position: absolute;
        right: 0;
        margin: 0;
      }
    }

    .tool-save {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1.6px;
      height: 48px;
    }
  }
}


// ----

.video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 300px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .finder-rect {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, #fff 2px, transparent 2px) 0 0, 
    linear-gradient(to right, #fff 2px, transparent 2px) 0 100%, 
    linear-gradient(to left, #fff 2px, transparent 2px) 100% 0, 
    linear-gradient(to left, #fff 2px, transparent 2px) 100% 100%, 
    linear-gradient(to bottom, #fff 2px, transparent 2px) 0 0, 
    linear-gradient(to bottom, #fff 2px, transparent 2px) 100% 0, 
    linear-gradient(to top, #fff 2px, transparent 2px) 0 100%, 
    linear-gradient(to top, #fff 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 30px 30px;
  }
}