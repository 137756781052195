.main-page,
.result-page {
    padding-top: 16vh;
    padding-bottom: 20px;

    .container {
        position: relative;
    }

    .page-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 1.33;
        max-width: 560px;
        margin-bottom: 20px;

        span {
            color: #ffb801;
        }
    }

    .page-subtitle {
        max-width: 560px;
        margin-bottom: 40px;
    }

    .btn-next {
        margin-right: 78px;
    }
}

@media all and (min-width: 610px) {
    body.app-locale--ru {
        .result-page {
            .page-title {
                font-size: 20px;
            }
        }
    }
}

@media all and (min-width: 1000px) {
    body.app-locale--ru {
 
        .main-bg {
            max-width: 460px;
        }

       .main-page {
            .page-title {
                font-size: 52px;
            }
    
            .page-subtitle {
                font-size: 30px;
            }
        }
    
        .result-page {
            .page-title {
                font-size: 33px;
            }
    
            .page-subtitle {
                font-size: 33px;
            }
        }
    }
}

.main-page {
    .main-bg {
        z-index: -5;
    }
}

.result-page {
    .steps {
        text-align: left;
    }

    .page-title {
        color: #ffb801;
        margin-bottom: 90px;
    }

    .btns-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .btn-choose {
        margin-bottom: 30px;
    }
}

.page-subtitle {
    font-size: 40px;
    line-height: 1.6;
}

.btn {
    font-weight: 700;
    line-height: 1;
    letter-spacing: 2.4px;
    text-align: center;
    text-transform: uppercase;
    width: 320px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 40px;
    border: solid 3px #000;
    box-sizing: border-box;

    &[disabled] {
        color: rgba(0, 0, 0, 0.1);
        border: solid 3px rgba(0, 0, 0, 0.1);
        background-color: #f2f2f2;
        box-shadow: none;
    }
}

.btn-next {
    color: #000;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffc501;
}

.btn-selection {
    color: #fff;
    background-color: #0087cb;
}

.btn-tg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: initial;
    position: relative;

    .icon-tg {
        width: 30px;
        margin-right: 20px;
    }

    .icon-tg-decor {
        position: absolute;
        top: 62px;
        left: 50%;
        transform: translateX(-50%);
        width: 86px;
    }
}

.btn-choose {
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    color: #0087cb;
    background: none;
    padding: 10px 0;
}

.btn-back {
    position: absolute;
    top: 30px;
    left: 25px;
    background: none;

    svg {
        width: 25px;
    }
}

.link-tg {
    display: flex;
    align-items: center;

    .icon {
        width: 84px;
        height: 84px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
        background-color: #0087cb;
        margin-right: 28px;

        svg {
            width: 46px;
            margin-right: 4px;
        }
    }

    span {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.2;
        color: #000;
    }
}

.btns-container {
    display: flex;
}

.main-bg {
    position: absolute;
    top: 0;
    right: -10px;
    max-width: 554px;
}

.create-page {
    padding-top: 16vh;
    
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .btns-container {
        flex-direction: column;
    }

    .page-subtitle {
        margin-bottom: 44px;
    }

    .btn-selection {
        margin-bottom: 28px;
    }
}

.input-link {
    width: 320px;
    height: 80px;
    padding: 31px 20px;
    border-radius: 12px;
    border: solid 1px #000;
    background-color: #fff;
    margin-bottom: 132px;
}

.steps {
    font-weight: 700;
    font-size: 60px;
    line-height: 1.33;
    text-align: center;
    margin-bottom: 20px;

    span {
        font-size: 40px;
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.loader-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 10px;
}

.loader {
    width: 200px;
    height: 48px;
    border: 2px solid #000;
    border-radius: 48px;
    padding: 10px;
    box-sizing: border-box;
}
  
.loader-color {
    height: 100%;
    border-radius: 48px;
    background-image: linear-gradient(90deg, #000, #000 8%, #fff 8%, #fff 12%, #000 12%, #000 18%, #fff 18%, #fff 22%, #000 22%, #000 28%, #fff 28%, #fff 32%, #000 32%, #000 38%, #fff 38%, #fff 42%, #000 42%, #000 48%, #fff 48%, #fff 52%, #ffc501 52%, #ffc501 58%, #fff 58%, #fff 62%, #ffc501 62%, #ffc501 68%, #fff 68%, #fff 72%, #ffc501 72%, #ffc501 78%, #fff 78%, #fff 82%, #ffc501 82%, #ffc501 88%, #fff 88%, #fff 92%, #ffc501 92%, #ffc501 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;
      animation: loader-show ease-out 1.6s infinite;
    }
  }
  
@keyframes loader-show {
    0% {width: 100%;}
    // 8% {width: 92%;}
    // 12% {width: 88%;}
    // 12% {width: 88%;}
    // 18% {width: 82%;}
    // 22% {width: 80%;}
    // 28% {width: 80%;}
    // 32% {width: 62%;}
    // 38% {width: 62%;}
    // 42% {width: 58%;}
    // 58% {width: 58%;}
    // 62% {width: 48%;}
    // 68% {width: 48%;}
    // 72% {width: 38%;}
    // 78% {width: 38%;}
    // 82% {width: 28%;}
    // 88% {width: 28%;}
    // 92% {width: 18%;}
    80% {width: 0%;}
    80.1% {width: 100%;}
}

.qr-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0;

    .container {
        height: 100%;
        padding: 0;
    }

    .btns-container {
        align-items: center;
        justify-content: center;
        max-width: 1180px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 30px;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);

        button {
            font-weight: 700;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.1);
            height: 50px;
            border-radius: 30px;
        }

        .btn-qr-gallery {
            width: 155px;
        }

        .btn-circle {
            display: none;  
            width: 50px;
            // display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 24px;
            }
        }
    }
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.7);
}

.modal {
    max-width: 600px;
    width: 90%;
    padding: 40px 60px;
    border-radius: 50px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    border: solid 2px #fd4c4c;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    box-sizing: border-box;

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 2.4px;
        margin-bottom: 10px;
    }

    p {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 30px;
    }

    button {
        font-weight: 700;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        width: 150px;
        height: 80px;
        border-radius: 100px;
        border: solid 3px #000;
        background-color: #ffc501;
    }
}

@media all and (max-width: 1100px) {
    .main-bg {
        max-width: 460px;
    }
}

@media all and (max-width: 1000px) {
    .page-subtitle {
        font-size: 24px;
    }

    .create-page {
        height: 86vh;
        box-sizing: border-box;
        padding-top: 50px;
        padding-bottom: 20px;

        .container {
            height: 100%;
        }

        .btns-container {
            flex-grow: 1;
        }

        form {
            flex-grow: 1
        }

        .page-subtitle {
            margin-bottom: 30px;
        }
    }

    .main-page,
    .result-page {
        padding-top: 50px;

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .page-title,
        .page-subtitle {
            text-align: center;
            max-width: 100%;
        }

        .page-title {
            font-size: 40px;
            order: 1;
            margin-bottom: 8px;
        }

        .page-subtitle {
            order: 3;
            margin-bottom: 60px;
        }

        picture {
            order: 2;
        }

        .btns-container {
            order: 4;
        }
    }

    .result-page {
        .btns-container {
            align-items: center;

            .btn-next {
                margin: 0;
                margin-top: 10px;
            }
        }
    }

    .main-bg {
        position: static;
        max-width: 325px;
        width: 100%;
        display: flex;
        margin: 0 auto 8px;
    }

    .steps {
        font-size: 40px;

        span {
            font-size: 24px;
        }
    }

    .input-link {
        margin-bottom: 30px;
    }
}

@media all and (max-width: 700px) {
    .qr-page {
        .btns-container {
            flex-direction: row;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .btns-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .link-tg {
        .icon {
            width: 62px;
            height: 62px;
            margin: 0;

            svg {
                width: 33px;
            }
        }

        span {
            display: none;
        }
    }

    .main-page,
    .result-page {
        .btn-next {
            margin: 0;
            margin-top: 45px;
        }
    }

    .btn {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.6px;
        width: 240px;
        height: 64px;
    }

    .input-link {
        font-size: 16px;
        width: 240px;
    }

    .btn-tg .icon-tg-decor {
        width: 75px;
        top: -68px;
    }

    .main-page .page-subtitle {
        margin-bottom: 80px;
    }
}

@media all and (max-width: 540px) {
    .page-subtitle {
        font-size: 16px;
    }

    .main-page,
    .result-page {
        padding-top: 20px;

        .page-title {
            font-size: 24px;
        }

        .page-subtitle {
            font-size: 16px;
        }
    }

    .qr-page {
        padding: 0;
    }

    .steps {
        font-size: 30px;
        line-height: 1.25;

        span {
            font-size: 16px;
        }
    }

    .modal {
        padding: 30px 40px;
        h2 {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 1.6px;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 20px;
        }

        button {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 1.6px;
            width: 120px;
            height: 64px;
        }
    }
}

@media all and (max-width: 430px) {
    .result-page .main-bg {
        max-width: 390px;
    }

    .result-page .btn-choose {
        font-size: 18px;
        letter-spacing: 2px;
    }
}

@media all and (max-height: 950px) and (orientation: landscape) {
    .result-page {
        .page-title {
            margin-bottom: 70px;
        }
        .btn-choose {
            margin-bottom: 16px;
        }
    }
}

@media all and (max-height: 870px) and (orientation: landscape) {
    .create-page {
        padding-top: 8vh;
    }

    .input-link {
        margin-bottom: 60px;
    }

    .result-page .page-title {
        margin-bottom: 40px;
    }

    .result-page .btn-choose {
        margin-bottom: 10px;
    }
}

@media all and (max-height: 800px) and (orientation: landscape) {
    .main-page, .result-page {
        padding-top: 6vh;
    }
}

@media all and (max-height: 720px) and (orientation: landscape) {
    .main-page .main-bg {
        top: -30px;
    }

    .main-page .page-title, .result-page .page-title {
        font-size: 52px;
        line-height: 1.3;
    }

    .main-page .page-subtitle, .result-page .page-subtitle {
        font-size: 38px;
        line-height: 1.4;
    }

    .result-page .page-subtitle {
        margin-bottom: 24px;
    }

    .result-page .page-title {
        margin-bottom: 40px;
    }

    .create-page {
        padding-top: 8vh;
    }

    .create-page .page-subtitle {
        line-height: 1.4;
        margin-bottom: 30px;
    }
    .input-link {
        margin-bottom: 30px;
    }
}

@media all and (max-height: 800px) and (orientation: portrait) {
    .main-page,
    .result-page {
        .page-title {
            margin-bottom: 16px;
        }

        .page-subtitle {
            margin-bottom: 24px;
        }

        .btn-next {
            margin-top: 24px;
        }
    }

    .main-page .page-subtitle {
        margin-bottom: 80px;
    }
}

@media all and (max-height: 740px) and (orientation: portrait) {
    .result-page {
        .steps {
            margin-bottom: 10px;
        }
        .btn-choose {
            padding: 6px 0;
            margin-bottom: 8px;
        }
        .page-subtitle {
            margin-bottom: 16px;
        }

        .main-bg {
            margin-bottom: 0;
            width: 90%;
        }
    }

    body.app-locale--ru {
        .result-page {
            .btn-choose {
                margin-bottom: 8px;
            }
        }
    }

    .main-page {
        .page-title {
            margin-bottom: 0;
        }

        .main-bg {
            max-width: 290px;
            margin-bottom: 0;
        }
    }
}

@media all and (max-height: 670px) and (orientation: portrait) {
    .main-bg {
        max-width: 270px;
    }

    .main-page .main-bg {
        max-width: 230px;
    }

    .result-page .page-title {
        margin-bottom: 12px;
    }

    .result-page .page-subtitle {
        margin-bottom: 10px;
    }

    .result-page .steps {
        margin-bottom: 0px;
    }

    .result-page .main-bg {
        margin-bottom: 0;
    }
}

@media all and (max-width: 640px) {
    .result-page .main-bg {
        max-width: 250px;
    }
}

@media all and (max-height: 610px) and (orientation: portrait) {
    .main-page .main-bg {
        max-width: 200px;
        margin-top: -10px;
    }
    .main-page .page-title {
        font-size: 20px;
        position: relative;
        z-index: 10;
    }

    .result-page .page-title {
        font-size: 20px;
    }
}

.input-link-label {
    margin-bottom: 10px;
}

.btn.white {
    color: #000;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    margin-bottom: 10px;
}

@media all and (min-width: 1000px) {
    .btn.white {
        margin-bottom: 20px;
    }
}

@media all and (max-width: 1000px) {
    .input-link-label {
        font-size: 20px;
    }
}